<template>
    <div>
        <div class="d-flex pb-2">
            <div>
                <v-icon @click="backToList"
                        class="text-black"
                        style="cursor: pointer;">mdi-arrow-left</v-icon>
            </div>
            <div class="align-center">
                <p style="font-size: 18px;"
                   class="font-weight-semibold text-black ml-5">
                 {{ $t('View App Users') }}
                </p>
            </div>
        </div>

        <v-divider></v-divider>

        <v-card class="mt-8">
            <v-card-title class="font-weight-semibold  pt-8">
                <div class="color text-green">{{ $t('App User Details') }}</div>
            </v-card-title>
            <v-row class="pl-6">
                <v-col cols="12"
                       lg="2"
                       md="2"
                       sm="3"
                       v-for="item in user"
                       :key="item.id">
                    <div class="color text-green text-caption">{{ item.id }}</div>
                    <p class="text-black font-weight-bold"
                       style="font-size:11px;">{{ item.text }}</p>
                </v-col>
            </v-row>
        </v-card>

        <v-card id="user-list"
                class="mt-6">

            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <span class="font-weight-semibold text-base text-green color">{{ $t('Bookings Listing') }}</span>

                </div>



                <div class="d-flex align-center pb-5">

                    <v-select v-model="selectStatus"
                              single-line
                              dense
                              outlined
                              :items="statuses"
                              variant="underlined"
                              item-text="name"
                              item-value="id"
                              hide-details
                              prepend-inner-icon="mdi-sort-variant"
                              :placeholder="$t('Select Status')"
                              class="user-list-search me-3"></v-select>

                    <v-text-field v-model="searchText"
                                  single-line
                                  dense
                                  outlined
                                  prepend-inner-icon="mdi-magnify"
                                  hide-details
                                  :placeholder="$t('Search')"
                                  width="33px"
                                  class="user-search me-3"></v-text-field>
                </div>
            </v-card-text>


            <v-data-table v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="filteredItems"
                          :options.sync="options"
                          :server-items-length="totalUserListTable"
                          :loading="loading"
                          class="text-no-wrap my-data-table">

                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>


                <template #[`item.id`]="{ item }">
                    <div class="font-size text-black"> {{ item.id }}</div>
                </template>
                <template #[`item.userName`]="{ item }">
                    <div class="font-size text-black"> {{ item.userName }}</div>
                </template>


                <template #[`item.boat_name`]="{ item }">
                    <div class="font-size text-black"> {{ item.boat_name }}</div>
                </template>
                <template #[`item.owner_name`]="{ item }">
                    <div class="font-size text-black"> {{ item.owner_name }}</div>
                </template>

                <template #[`item.status`]="{ item }">
                    <!-- <div class="font-size text-black"> {{ item.status }}</div> -->
                    <v-chip :color="resolveStatusVariant(item.status).variant"
                            :class="`v-chip-light-bg ${resolveStatusVariant(item.status).variant}--text font-weight-thin text-caption`">
                        {{ $t(resolveStatusVariant(item.status).name) }}
                    </v-chip>
                </template>

                <template #[`item.packages`]="{ item }">
                    <div class="font-size text-black"> {{ item.packages }}</div>
                </template>
                <template #[`item.name`]="{ item }">
                    <div class="font-size text-black"> {{ item.name }}</div>
                </template>

                <template #[`item.created_at`]="{ item }">
                    <div class="font-size text-black"> {{ item.created_at }}</div>
                </template>


            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import { mdiEyeOutline } from '@mdi/js';
import moment from 'moment';

// composition function
import router from '@/router';
import userServices from '@/services/user-services';
import userBookingList from './userBookingList';

export default {
    data: () => ({
        searchText: '',
        applicationList: '',
        user: [

        ],
        statuses: [
            {
                id: '',
                name: 'All'
            },
            {
                "id": 'pending',
                "name": 'Pending'
            },
            {
                "id": 'confirmed',
                "name": 'Confirmed'
            },
            {
                "id": 'cancelled',
                "name": 'Cancelled'
            },
            {
                "id": 'completed',
                "name": 'Completed'
            },
            {
                "id": 'in-progress',
                "name": 'InProgress'
            }
        ],

        route: router.currentRoute.params.id,
    }),
    methods:
    {
        backToList()
        {
            router.push({ name: 'user-list' })
        }
    },
    computed: {
        filteredItems()
        {
            if (this.searchText)
            {
                const query = this.searchText.toLowerCase();
                return this.userListTable.filter(item =>
                    Object.values(item).some(
                        value => value && value.toString().toLowerCase().includes(query)
                    ))
            }
            return this.userListTable
        }
    },
    mounted()
    {
        this.id = this.route;
        userServices.getUser(this.route).then(resp =>
        {
            if (resp.statusCode == 200)
            {
                this.user = [
                    { id: 'User ID', text: resp.data.users[0].id },
                    { id: 'App User name', text: resp.data.users[0].first_name + ' ' + resp.data.users[0]?.last_name },
                    { id: 'Mobile No.', text: resp.data.users[0].phone },
                    { id: 'Email Address', text: resp.data.users[0].email },
                    { id: 'Joining Date', text: moment(new Date(resp.data.users[0].created_at)).format('DD/MM/yyyy') }
                ]
                // console.log(this.user);
            }

        })
    },
    setup()
    {
        const {
            userListTable,
            searchQuery,
            tableColumns,
            options,
            totalUserListTable,
            loading,
            selectedTableData,
            resolveStatusVariant,
            id,
            resolveRole,
            selectStatus,
        } = userBookingList()

        return {
            tableColumns,
            searchQuery,
            options,
            totalUserListTable,
            userListTable,
            loading,
            selectedTableData,
            resolveStatusVariant,
            id,
            resolveRole,
            selectStatus,
            icons: {
                mdiEyeOutline
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}

.text-black {
    color: #000;
}

.size {
    font-size: 20px;
}

.color {
    color: #00B2A9;
}

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}


::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 0.875rem;
    height: 3.125rem;
    border-left: 1px solid #efeded !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    border-bottom: thin solid rgba(94, 86, 105, 0.14);
    border-right: 1px solid #efeded;
}</style>
