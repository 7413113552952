import { ref, watch } from '@vue/composition-api';

import bookingServices from '@/services/booking-services';

import store from '@/store';
import { useUtils } from '@core/libs/i18n';
import moment from 'moment';
const { t } = useUtils()
export default function userBookingList()
{

    const userListTable = ref([])

    // Table Handlers
    const tableColumns = [
        {
            text: 'ID',
            align: 'start',
            value: 'id',
            sortable: false
        },
        { text: t('User Name'), value: 'userName', sortable: false, align: 'center' },
        { text: t('Boat Name'), value: 'boat_name', sortable: false, align: 'center' },
        { text: t('Owner Name'), value: 'owner_name', sortable: false, align: 'center' },
        { text: t('Status'), value: 'status', sortable: false, align: 'center' },
        { text: t('Packages'), value: 'packages', sortable: false, align: 'center' },
        { text: t('City'), value: 'name', sortable: false, align: 'center' },
        { text: t('Created'), value: 'created_at', sortable: false, align: 'center' },
        
    ]

    const searchQuery = ref('')
    const selectStatus = ref('')
    const updateStatus = ref('')
    const rejectionStatus = ref('')

    const id = ref('')

    const options = ref({
        // sortBy: ['id'],
        // sortDesc: [true],
        page: 1,
        itemsPerPage: 10
    })
    const totalUserListTable = ref(0)
    const loading = ref(false)
    const statusFilter = ref(null)
    const selectedTableData = ref([])
    const checkBooking = ref([])

    
    const checkStatus = status =>
    {
        if (status === 'in_progress') return true;
        // if (status === 'confirmed') return { variant: 'primary', name: 'Confirmed' }
        // if (status === 'cancelled') return { variant: 'error', name: 'Cancelled' }
        // if (status === 'in_progress') return { variant: 'warning', name: 'In Progress' }
        // if (status === 'completed') return { variant: 'success', name: 'Completed' }
    }

    const resolveStatusVariant = status =>
    {
        if (status === 'pending') return { variant: 'success', name: 'Pending' }
        if (status === 'confirmed') return { variant: 'primary', name: 'Confirmed' }
        if (status === 'cancelled') return { variant: 'error', name: 'Cancelled' }
        if (status === 'in_progress') return { variant: 'warning', name: 'In Progress' }
        if (status === 'completed') return { variant: 'success', name: 'Completed' }
        // if (status === 'pending_boat_inspection') return { variant: 'info', name: 'Pending Inspection' }

        return { variant: 'secondary', name: 'Not Available' }
    }

    const fetchUsers = () =>
    {
        
         // console.log(id);
            bookingServices.getUsersBookingsList(id?.value,selectStatus?.value).then(resp =>
            {

                // const { filteredData, total } = response.data
                if (resp.status == 200)
                {
                    store.set('accounts/loader', false);
                    // console.log(resp.data);
                    let data = resp.data.data.bookings;
                    data.forEach(element =>
                    {
                        element.created_at = moment(new Date(element.created_at)).format('DD/MM/yyyy')
                        if (element.user)
                        {
                            element.userName = element.user.first_name;
                        }
                        if (element.boat)
                        {
                            element.boat_name = element.boat.boat_name;
                            if (element.boat.boats_owner)
                            {
                                element.owner_name = element.boat.boats_owner.owner_name
                            }
                            if (element.boat.boat_packages)
                            {
                                element.packages = element.boat.boat_packages.length > 0 ? 'Yes' : 'No'
                            }
                        }
                        if (element.city)
                        {
                            element.name = element.city.name;
                        }

                    });
                    totalUserListTable.value = resp.data.data.pagination.count
                    userListTable.value = data;
                    loading.value = false
                }

            }) 

    }

    watch([searchQuery, selectStatus, updateStatus, rejectionStatus, statusFilter, checkBooking, options,id], () =>
    {
        // start loading

        loading.value = true
        selectedTableData.value = []
        fetchUsers()
    })

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveRole = role =>
    {
        if (role === 1) return 'Admin'
        if (role === 2) return 'Approver'
        if (role === 3) return 'Initiator'

        return 'N/A'
    }

    return {
        tableColumns,
        searchQuery,
        selectStatus,
        updateStatus,
        rejectionStatus,
        options,
        id,
        userListTable,
        statusFilter,
        checkBooking,
        totalUserListTable,
        loading,
        selectedTableData,
        fetchUsers,
        resolveRole,
        resolveStatusVariant,
        checkStatus
    }
}
